import { gql } from "@apollo/client";

//User
export const G_USERS = gql`
  query User {
    users {
      id
      name
    }
  }
`;

//Brand
export const G_SLUGBRAND = gql`
  query BrandSlug($slug: String!) {
    brandSlug(slug: $slug) {
      id
      name
      slug
      settings {
        logoLogin {
          id
          type
          file {
            id
            name
            url
          }
        }
        logoHightlight {
          id
          type
          file {
            id
            name
            url
          }
        }
        downloadImage {
          id
          url
        }
        colorHightlight
        colorPrimaryLogin
        colorSecondaryLogin
      }
      logos {
        id
        type
        file {
          id
          name
          url
        }
      }
      files {
        id
        type
        file {
          id
          name
          url
        }
      }
      sections {
        id
        name
        slug
        subsections {
          id
          name
          slug
          cover {
            url
            name
            alt
          }
          modules {
            id
            name
            content
            description
          }
          downloads {
            link
            name
          }
        }
        modules {
          id
          name
          content
          description
        }
        downloads {
          link
          name
        }
      }
      highlights {
        id
        name
        slug
        cover {
          url
          name
          alt
        }
        subsections{
          id
        }
        modules {
          id
        }
      }
      holding {
        id
      }
      created_by {
        id
      }
      update_by {
        id
      }
    }
  }
`;

export const G_BRAND = gql`
  query Brand($id: ID!) {
    brand(id: $id) {
      id
      name
      slug
      settings {
        logoLogin {
          id
          type
          file {
            id
            name
            url
          }
        }
        logoHightlight {
          id
          type
          file {
            id
            name
            url
          }
        }
        downloadImage {
          id
          url
        }
        colorHightlight
        colorPrimaryLogin
        colorSecondaryLogin
      }
      logos {
        id
        type
        file {
          id
          name
          url
        }
      }
      files {
        id
        type
        file {
          id
          name
          url
        }
      }
      sections {
        id
        name
        slug
        subsections {
          id
          name
          slug
          downloads {
            link
            name
          }
        }
        modules {
          id
          name
        }
        downloads {
          link
          name
        }
      }
      highlights {
        id
        name
        slug
        cover {
          id
        }
        subsection{
          id
        }
        modules {
          id
        }
      }
      holding {
        id
      }
      created_by {
        id
      }
      update_by {
        id
      }
    }
  }
`;

export const G_SECTION = gql`
  query Section($id: ID!) {
    section(id: $id) {
      id
      name
      subsections {
        id
        name
        cover {
          id
          url
          name
          alt
        }
        downloads {
          link
          name
        }
      }
      downloads {
        link
        name
      }
    }
  }
`;

export const G_MODULE = gql`
  query Module($id: ID!) {
    module(id: $id) {
      id
      order
      content
      name
      description
    }
  }
`;

export const G_FILE = gql`
  query File($id: ID!) {
    file(id: $id) {
      id
      type
      name
      url
      alt
      width
      height
      size
    }
  }
`;
