import React from "react";
import { TypeBlock } from "../../../types/blocks";

const Box = ({
    id,
    name,
    attributes,
    className,
    children,
    style }
    : TypeBlock) => {
    const { tag } = attributes;

    return React.createElement(
        tag || "div",
        {
            className: className,
            style: style,
            "data-id": id,
        },
        children
    );
};

export default Box;
