import { useQuery } from "@apollo/client";
//graphql
import {
  G_FILE,
  G_MODULE,
  G_SECTION,
  G_SLUGBRAND,
  G_USERS,
} from "../graphql/Queries";
import { useEffect, useState } from "react";

//Users
export const QUERY_USERS = () => {
  const { loading, error, data } = useQuery(G_USERS);

  loading && console.log("Loading...");
  error && console.log(`Error! ${error.message}`);
  return data;
};

//Brands
export const QUERY_BRANDSLUG = () => {
  const [subdomain, setSubDomain] = useState("");
  useEffect(() => {
    const host = window.location.host;

    const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
    if (arr.length > 0) setSubDomain(arr[0]);
  }, []);

  const { loading, error, data } = useQuery(G_SLUGBRAND, {
    fetchPolicy: "cache-and-network",
    variables: { slug: subdomain },
  });

  loading && console.log("Loading...");
  error && console.log(`Error! ${error.message}`);
  return data && data.brandSlug;
};

//Sections
export const QUERY_SECTION = (id: string) => {
  const { loading, error, data } = useQuery(G_SECTION, {
    fetchPolicy: "cache-and-network",
    variables: { id: id },
  });

  loading && console.log("Loading...");
  error && console.log(`Error! ${error.message}`);
  return data;
};

//Modules
export const QUERY_MODULE = (id: string) => {
  const { loading, error, data } = useQuery(G_MODULE, {
    fetchPolicy: "cache-and-network",
    variables: { id: id },
  });

  loading && console.log("Loading...");
  error && console.log(`Error! ${error.message}`);
  return data;
};

//Files
export const QUERY_FILE = (id: string) => {
  const { loading, error, data } = useQuery(G_FILE, {
    fetchPolicy: "cache-and-network",
    variables: { id: id },
  });

  loading && console.log("Loading...");
  error && console.log(`Error! ${error.message}`);
  return data;
};
