import { useParams } from 'react-router-dom'
//components
import { Left } from '../../components/Structure/Left'
import { Right } from '../../components/Structure/Right'
//styles
import m from "./modules.module.scss";
import "./modules.scss";
import { QUERY_BRANDSLUG } from '../../lib/Queries'
import Blocks from '../../components/Blocks/blocks'

export const Modules: React.FC<{}> = ({ }) => {
    const settings = QUERY_BRANDSLUG()?.settings;
    const sections = QUERY_BRANDSLUG()?.sections;
    const { section, subsection, module } = useParams();

    const Item: any = subsection ?
        sections?.find((i: any) => i.slug === section)?.subsections
            .find((i: any) => i.slug === subsection) :
        sections?.find((i: any) => i.slug === section);
    const foundModule = Item?.modules?.find((m: any) => m.id === module);

    return (
        <>
            <Left
                urlLogoTop={'/'}
                settings={settings}
                section={Item}
                local='module'
                moduleId={module}
                logoHeight={'34px'}
                title={Item?.name}
                inside={foundModule?.description}
                classStyle={'left_section'}
                modules={Item?.modules}
                downloads={Item?.downloads}
            />
            <Right
                top={true}
                logoHeight={'34px'}
                borderRadius={'34px'}
                classStyle={'right_section'}
            >
                <div className={m.columns}>
                    <div className={m.containers}>
                        {foundModule &&
                            <Blocks blocks={JSON.parse(foundModule?.content)} />
                        }
                    </div>
                </div>
            </Right>
        </>
    )
}