import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
//utils
import { getCookie } from '../utils/cookies';

// Crie um link HTTP para a sua API GraphQL
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL, // Substitua pela URL do seu servidor GraphQL
});

// Configuração para adicionar o token de autenticação (se necessário)
const authLink = setContext((_, { headers }) => {
  const token = getCookie(process.env.REACT_APP_COOKIE as string)
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Crie uma instância do Apollo Client
export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
