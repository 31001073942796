import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
//@hocs
import { apolloClient } from './hoc/Apollo';
import AppRoute from './hoc/Routes';
//@styles
import './styles/globals.scss'
import m from './styles/globals.module.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      
        <div className={m.container_bu}>
          <div className={m.columns}>
            <AppRoute />
          </div>
        </div>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
