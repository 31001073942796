'use client'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
//components
import { Menu } from '../../components/Menu/index'
//utils
import { useScrollPosition } from '../../utils/scroll'
import { ICON_Downloads, SLogo } from '../../utils/svgs'

import s from "./structure.module.scss";
import { QUERY_MODULE } from '../../lib/Queries'

export const Left = ({
    urlLogoTop,
    section,
    settings,
    local,
    moduleId,
    logoHeight,
    title,
    sub_title,
    inside,
    classStyle,
    downloads
}: any) => {
    const modules = section?.modules;
    const module = QUERY_MODULE(moduleId)?.module;

    const { section: sectionSlug } = useParams();

    const scrollY = useScrollPosition();

    return (
        <div className={`${s.column_bu} ${s.left_bu} ${s[classStyle]}`}>
            <div className={s.top_left}>
                <div className={s.bu_logo} style={logoHeight && { height: logoHeight }}>
                    {local === "module" ?
                        <Link to={urlLogoTop ? urlLogoTop : ''}>
                            <img src={settings?.logoHightlight?.file?.url} alt={settings?.logoHightlight?.file?.name} />
                        </Link> :
                        <Link to={urlLogoTop ? urlLogoTop : ''}>
                            <SLogo color={settings?.colorHightlight}/>
                        </Link>
                    }
                </div>
            </div>
            <div className={s.bu_left_content}>
                <div className={s.informationPage}
                    style={{
                        minHeight: scrollY < 110 ? `calc(100vh - (110px - ${scrollY}px))` : '100vh',
                    }}
                >
                    <div className={s.informations}>
                        {title ?
                            <h2 className={s.title} style={{

                            }}>{title}</h2> :
                            ''
                        }
                        {modules?.length > 1 ?
                            <h3 className={s.sub_title}> {module?.name}</h3> :
                            ''
                        }
                        {inside ? (
                            <div className={s.inside} dangerouslySetInnerHTML={{
                                __html: inside ?
                                    inside :
                                    inside
                            }} />) :
                            ''
                        }
                    </div>
                    {/* <div>
                        <button onClick={generatePdf}>
                            Generate PDF
                        </button>
                        <a download="json_data.pdf">Download PDF</a>
                    </div> */}
                    <div>
                        <div className={s.modules}>
                            {modules && modules.length > 1 ? modules?.map((module: any, index: number) => {
                                return <div className={s.module} key={index}>
                                    <Link
                                        to={`/${section?.subsection ? 's' : 'sb'}/${sectionSlug}/${section?.subsection ? '' : `${section?.slug}/`}${module.id}`}
                                        style={{
                                            color: settings?.colorHightlight
                                        }}
                                    >
                                        <b>{module.name}</b>
                                    </Link>
                                </div>
                            })
                                : ''}
                        </div>
                        <div className={s.downloads}>

                            {downloads ? downloads?.map((download: any, index: number) => {
                                return <div className={s.download} key={index}>
                                    <ICON_Downloads color={settings?.colorHightlight} />
                                    <Link to={download.link} target='_blank' rel="noreferrer">{download.name}</Link>
                                </div>
                            })
                                : ''}
                        </div>
                    </div>
                </div>
                <Menu />
            </div>
        </div >
    )
}
