import { createContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import jwt_decode from "jwt-decode";
//hocs
import { IAuthProvider, IContext, IUser } from "./AuthTypes";
//graphqls
import { M_LOGIN } from "../graphql/Mutations";

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
    const [LoginUser] = useMutation(M_LOGIN);
    const [user, setUser] = useState<IUser | null>();

    useEffect(() => {
        const userLogged = JSON.parse(localStorage.getItem("_bu_l") as string);
        if (userLogged) {
            setUser(userLogged);
        }
    }, []);

    async function authenticate(username: string, password: string) {
        try {
            await LoginUser({
                variables: {
                    data: {
                        userName: username,
                        email: username,
                        password: password,
                    },
                },
            }).then((res) => {
                const decoded: any = jwt_decode(res.data.loginUser.token);
                setUser({ ui: decoded.id });

                localStorage.setItem(
                    "_bu_l",
                    JSON.stringify({
                        ui: decoded.id,
                        exp: decoded.exp
                    })
                );
                document.cookie = `_bu_t=${res.data.loginUser.token}; expires=${new Date(decoded.exp * 1000)}; Secure`
            })
            return 'success';
        } catch (err) {
            return 'error';
        }
    }


    const logout = () => {
        setUser(null);
        localStorage.clear();
        document.cookie = `_bu_t=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };

    return (
        <AuthContext.Provider value={{ ...user, authenticate, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
