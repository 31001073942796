import { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom'
//hocs
import { AuthContext } from "./AuthContext";
//utils
import { getCookie } from '../utils/cookies';

export const useAuth = () => {
  const context = useContext(AuthContext);

  return context;
};

export function AuthRoute({ Component, restrict }: any) {
  const [loading, setLoading] = useState(true);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
      const storage = localStorage.getItem('_bu_l');
      const time = storage ? JSON.parse(storage).exp : null;
      const now = new Date().getTime() / 1000;

      if (getCookie(process.env.REACT_APP_COOKIE as string) && time > now) {
          setLogged(true);
      } else {
          setLogged(false);
      }

      setLoading(false);
  }, [restrict]);

  if (loading) {
      return <div>Loading</div>;
  }

  if (!logged && restrict) {
      return <Navigate to="/signin" replace={true} />;
  }

  if (logged && !restrict) {
      return <Navigate to="/" />;
  }

  return <Component />;
}
